// Non Admin User Profile//
.profile-detail-wrap {
  .profile-view {
    .profile-picture {
      .image-wrap {
        display: inline-flex;
        position: relative;
        .image {
          background-color: var(--lightgrey);
          border: 2px solid var(--black);
          border-radius: 10px;
          height: 130px;
          width: 130px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
        }
        .image img {
          object-fit: cover; /* Make the image cover the entire circle */
          width: 100%;
          height: 100%;
        }
        button.upld-btn {
          position: absolute;
          right: 5px;
          bottom: 8px;
          height: 30px !important;
          min-width: 30px !important;
          background-color: var(--black);
          border-radius: 50%;

          svg {
            fill: var(--white);
            font-size: 16px;
          }
        }
      }
      .btn-wrap {
        width: 100%;
        margin-top: 16px;
        .outline-btn {
          margin-top: 16px;
        }
      }
    }
    .app-details {
      width: 40%;
      @media (max-width: 1550px) {
        width: 60%;
      }
      @media (max-width: 992px) {
        width: 100%;
      }
      h3 {
        span {
          font-size: 16px !important;
        }
      }
    }
  }
}
.custom-auto {
  .MuiTabs-fixed {
    overflow: auto !important;
  }
}
// Non Admin User Profile END//
