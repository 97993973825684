.holiday-setting-card-heading {
    // position: sticky;
    // z-index: 99;
    background-color: white;
    top: 0;
    background-color: white;
    top: 0;
    height: 10px;

    .btn-prev {
        margin-top: 4px;
        margin-right: -16px;
    }

    .btn-next {
        margin-top: 4px;
        margin-left: -4px;
    }

    .btn-prev:hover {
        background-color: transparent;
    }

    .btn-next:hover {
        background-color: transparent;
    }
}
.css-1u9des2-indicatorSeparator{
display: none;
}
.outline-btn {
    text-transform: capitalize !important;
    font-size: 14px !important;
    color: var(--black) !important;
    // border-color: pink !important;
    // border-width: 1.5px !important;
    border: 1.5px solid black !important;
    background-color: transparent !important;
    &:hover {
      background-color: var(--lightgrey) !important;
    }
  }
.holiday-setting-left-wrapper {
    // height: calc(100vh - 327px);
    overflow: auto;
    margin-top: 2rem;
    padding-right: 20px;

    @media (max-width: 1200.98px) {
        padding-right: 0px;
    }
}
.css-1i6nw3-control {
    border-radius: 8px !important;
}
.css-kwdkcd-IndicatorsContainer{
    padding-right: 7px !important;
    font-size:16px;
}
.holiday-card-date-ui {
    height: 70%;
    display: flex;
    flex-direction: column;
    border: 1px solid #ebf5d9;

    .holiday-card-content-header {
        background-color: #e8f8ca;
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 15%;
        align-items: center;
        justify-content: center;
    }

    .holiday-card-content {
        min-height: auto;
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .holiday-card-name-content {
        display: flex !important;
        align-items: center !important;
        margin-top: -35px !important;
    }
}

.checkbox-action {
    display: flex;
    gap: 10px;

    .action-btn {
        min-width: 30px !important;
        min-height: 30px !important;
        max-width: 30px !important;
        max-height: 30px !important;
        border-radius: 5px !important;
    }
}

.setting-grid-list-scroll {
    min-height: calc(100vh - 186px);
    padding: 24px;
    overflow: hidden;

    .setting-grid-list {
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        .setting-card {
            align-items: center;
            background-color: var(--body-bg);
            border: 2px solid var(--textgrey);
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            gap: 12px;
            height: 160px;
            justify-content: center;
            width: 18%;

            &:hover {
                background-color: var(--light-grey);
            }

            @media (max-width: 1200.98px) {
                width: 40%;
            }

            @media (max-width: 991.98px) {
                width: 47%;
            }

            @media (max-width: 768.98px) {
                width: 100%;
            }

            .setting-icon {
                margin: 0;
                width: 65px;

                img {
                    width: 100%;
                }
            }

            .setting-name {
                margin: 0;
                color: var(--primary-text-color);
            }
        }
    }
}

.form-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.setting-count-card-wrapper {
    .count {
        color: var(--green);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 42px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
        margin-bottom: 10px;
    }

    .title {
        color: var(--primary-text-color);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
    }
}

.checkbox-style-heading {
    .MuiFormControlLabel-label {
        color: var(--primary-text-color);
        // text-align: right;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
    }

    .MuiRadio-root,
    .MuiCheckbox-root {
        svg {
            fill: var(--textgrey);
        }
    }

    .MuiCheckbox-root.Mui-checked svg,
    .MuiRadio-root.Mui-checked svg {
        fill: var(--primary-text-color);
    }
}

.setting-page-wrapper {
    justify-content: center;

    .left-wrapper {
        margin-right: 0;

        @media (max-width: 1190.98px) {
            margin-right: 32px;
        }
    }

    .right-wrapper {
        margin-left: 0;

        @media (max-width: 1190.98px) {
            margin-left: 32px;
        }
    }
}

.holiday-setting-listing {
    border-right: 1px solid #f0f0f0;
    padding: 0;
}

.checkbox-list-wrapper {
    padding: 24px;

    ul {
        overflow: auto;
        padding: 0;
        padding-left: 0;
        min-height: calc(100vh - 270px);
        max-height: calc(100vh - 270px);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        li {
            padding: 0;
            border-right: 1px solid #f0f0f0;
            display: flex;
            gap: 8px;
            justify-content: space-between;
            background-color: transparent !important;

            .checkbox-style-heading {
                margin-right: 0;
                margin-left: 0;
            }

            span {
                padding: 0;

                &.MuiFormControlLabel-label {
                    color: var(--primary-text-color);
                    font-size: 14px !important;
                    line-height: 14px !important;
                    font-style: normal;
                    font-weight: 500;
                    margin-left: 8px;
                    margin-top: 1px;
                    white-space: nowrap;
                }
            }

            .checkbox-action {
                display: flex;
                gap: 10px;

                .action-btn {
                    min-width: 30px !important;
                    min-height: 30px !important;
                    max-width: 30px !important;
                    max-height: 30px !important;
                    border-radius: 5px !important;
                }
            }
        }
    }
}
.content-wrapper {
    margin: 24px 32px 32px 32px !important;
}
@media (max-width: 991.98px) {
    .content-wrapper {
        margin: 24px 16px 50px 16px !important;
    }
}
.content-header {
    margin: 24px 32px 0px 32px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    row-gap: 24px !important;
    h2 {
        margin: 0;
    }
}
.content-header {
    &.right-wrapper {
        @media (max-width: 1190.98px) {
            margin-top: 0;
        }
    }
}

.content-wrapper {
    &.left-wrapper {
        @media (max-width: 1190.98px) {
            margin-bottom: 0 !important;
        }
    }
}
.card-wrapper{
    background-color: var(--white) !important;
    border-radius: 12px;
    padding: 24px
  }
  .ck.ck-voice-label {
    display: none !important;
}
.report-card-wrapper {
    align-items: center;
    background-color: var(--body-bg);
    border: 2px solid var(--textgrey);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 12px;
    height: 160px;
    justify-content: center;
    .app-logo {
        margin: 0;
        img {
            height: 70px;
            width: auto;
        }
    }
    .setting-icon {
        margin: 0;
        width: 65px;

        img {
            width: 100%;
        }
    }

    .card-name {
        margin: 0;
        font-size: 20px !important;
        color: var(--primary-text-color);
    }
}
.content-wrapper{
    // margin: 24px 16px 35px 16px;
}
.side-drawer {
    .MuiDrawer-paper {
        .sidebar-tab-wrapper {
            margin-bottom: 15px;

            .sidebar-tab {
                padding: 10px 24px;
                border-bottom: 1px solid var(--border);

                .tab-label-btn {
                    color: var(--primary-text-color);
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    opacity: 1 !important;

                    &.Mui-selected {
                        color: var(--primary-text-color) !important;
                    }
                }

                .contact-label {
                    color: var(--primary-text-color);
                    font-weight: 700;
                    cursor: default;
                }

                .MuiTabs-indicator {
                    height: 3px;
                    background-color: transparent;
                    border-radius: 5px;
                }
            }

            .filter-content-wrapper {
                .MuiBox-root {
                    padding: 0 !important;

                    .filer-accordion-wrapper {
                        max-width: 500px;
                        overflow: auto;
                        max-height: calc(100vh - 65px);

                        .MuiAccordion-root {
                            // border: 0;
                            padding-top: 12px;
                            padding-bottom: 12px;
                            border-bottom: 1px solid var(--border) !important;

                            &.Mui-expanded {
                                margin: 0;
                            }

                            &:before {
                                display: none !important;
                            }

                            box-shadow: unset;
                            border-radius: 0;

                            .MuiAccordionSummary-expandIconWrapper {
                                background-color: var(--edit-btn);
                                width: 30px;
                                height: 30px;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                &:hover {
                                    background-color: var(--edit-btn-hover);
                                }

                                svg {
                                    width: 20px;
                                    height: 20px;

                                    path {
                                        color: var(--primary-text-color);
                                    }
                                }
                            }

                            .accordion-title {
                                padding: 12px 24px;
                                min-height: auto !important;

                                .action-btn {
                                    min-width: 30px !important;
                                    max-width: 30px;
                                    height: 30px;
                                    padding: 0 !important;

                                    svg {
                                        width: 20px;
                                        height: 20px;
                                    }
                                }

                                .MuiAccordionSummary-content {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding-right: 16px;
                                    margin: 0;

                                    .title-content {
                                        color: red !important;
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 16px;
                                    }
                                }
                            }

                            .filter-accordion-details {
                                padding: 12px 24px 0 24px;

                                .saved-filter-wrapper {
                                    margin-bottom: 12px;

                                    .saved-filter-content {
                                        display: flex;
                                        align-items: flex-start;

                                        span {
                                            font-feature-settings: 'clig' off,
                                                'liga' off;
                                            font-size: 14px;
                                            font-style: normal;
                                            font-weight: 500;
                                            line-height: normal;
                                            letter-spacing: 0.4px;

                                            &.title {
                                                color: var(--textgrey);
                                                width: 190px;
                                                white-space: nowrap;
                                            }

                                            &.desc {
                                                color: var(
                                                    --primary-text-color
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.filter-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    margin-top: 24px;

    .btn {
        width: 120px;
    }
}

.ql-toolbar.ql-snow {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.ql-container.ql-snow {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    min-height: 150px;
}

.ql-snow.ql-toolbar button:hover {
    background: var(--primary-text-color);
    border-radius: 5px;
    color: var(--white);
}

.ql-snow.ql-toolbar button.ql-active {
    background: var(--primary-text-color);
    border-radius: 5px;
    color: var(--white) !important;
}

.ql-snow.ql-toolbar button svg {
    color: var(--white);
}

.ql-snow.ql-toolbar button.ql-active {
    color: var(--white);
}

.ql-container.ql-snow:focus .ql-editor {
    background-color: black;
    color: var(--white);
}

.ql-editor {
    font-size: initial !important;
}

.ql-indent-1 span {
    font-size: initial !important;
}

.setting-count-card-wrapper {
    .count {
        color: var(--green);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 42px !important;
        font-style: normal !important;
        font-weight: 600 !important;
        line-height: normal !important;
        margin-bottom: 10px;
    }

    .title {
        color: var(--primary-text-color);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-size: 20px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal;
    }
}

.setting-sort-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    .muilt-select-field {
        width: 200px;
    }

    .sort-by-title {
        color: var(--primary-text-color);
        text-align: right;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
    }
}

.checkbox-style-heading {
    .MuiFormControlLabel-label {
        color: var(--primary-text-color);
        // text-align: right;
        font-size: 18px !important;
        font-style: normal;
        font-weight: 500 !important;
        line-height: normal;
    }

    .MuiRadio-root,
    .MuiCheckbox-root {
        svg {
            fill: var(--textgrey);
        }
    }

    .MuiCheckbox-root.Mui-checked svg,
    .MuiRadio-root.Mui-checked svg {
        fill: var(--primary-text-color);
    }
}

.setting-page-wrapper {
    .left-wrapper {
        margin-right: 0;

        @media (max-width: 1190.98px) {
            margin-right: 32px;
        }
    }

    .right-wrapper {
        margin-left: 0;

        @media (max-width: 1190.98px) {
            margin-left: 32px;
        }
    }
}

.checkbox-list-wrapper {
    padding: 24px;

    ul {
        overflow: auto;
        padding: 0;
        padding-left: 0;
        min-height: calc(100vh - 270px);
        max-height: calc(100vh - 270px);
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // column-gap: 28px;

        li {
            width: 300px;
            padding: 0;
            padding-bottom: 16px;
            padding-right: 30px;
            padding-left: 10px;
            border-right: 1px solid #f0f0f0;
            display: flex;
            gap: 8px;
            justify-content: space-between;

            .checkbox-style-heading {
                margin-right: 0;
                margin-left: 0;
            }

            span {
                padding: 0;

                &.MuiFormControlLabel-label {
                    color: var(--primary-text-color);
                    font-size: 14px !important;
                    line-height: 14px !important;
                    font-style: normal;
                    font-weight: 500;
                    margin-left: 8px;
                    margin-top: 1px;
                    white-space: nowrap;
                }
            }

            .checkbox-action {
                display: flex;
                gap: 10px;

                .action-btn {
                    min-width: 30px !important;
                    min-height: 30px !important;
                    max-width: 30px !important;
                    max-height: 30px !important;
                    border-radius: 5px !important;
                }

                // }
            }
        }
    }
}

.content-header {
    &.right-wrapper {
        @media (max-width: 1190.98px) {
            margin-top: 0;
        }
    }
}

.content-wrapper {
    &.left-wrapper {
        @media (max-width: 1190.98px) {
            margin-bottom: 0 !important;
        }
    }
}

.filter-apply-wrapper .outline-btn.add-btn span svg {
    fill: var(--primary-text-color);
}

.side-drawer {
    .MuiDrawer-paper {
        max-width: 900px;
        width: 100%;
        .sidebar-tab-wrapper {
            margin-bottom: 15px;

            &.activity-log-wrapper-1 {
                .close-btn {
                    top: 14px;
                }
                .sidebar-tab {
                    border-bottom: 1px solid var(--border);
                    padding: 0 24px;

                    .tab-label-btn {
                        padding-left: 0;
                        padding-right: 0;
                        min-width: 120px;
                        // color: var(--textgrey);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;

                        &.Mui-selected {
                            color: var(--primary-text-color);
                        }
                    }

                    .MuiTabs-indicator {
                        height: 3px;
                        // background-color: var(--primary-text-color);
                        background-color: transparent;
                        border-radius: 5px;
                        left: 0;
                        width: 120px;
                    }
                }

                .add-activity-log-btn {
                    justify-content: flex-end;
                    display: flex;
                    width: 100%;

                    button {
                        margin-right: 10px;
                    }
                }
                .activity-log-list-wrapper {
                    display: flex;
                    flex-direction: column;
                    max-height: calc(100vh - 100px);
                    padding-right: 10px !important;
                    overflow: auto;

                    .activity-card-wrapper {
                        display: flex;
                        align-items: flex-start;
                        gap: 24px;
                        position: relative;
                        padding-bottom: 36px !important;

                        &:not(:last-child) {
                            &:before {
                                content: '';
                                width: 2px;
                                height: 100%;
                                position: absolute;
                                left: 25px;
                                background: rgba(217, 217, 217, 0.5);
                                z-index: -1;
                            }
                        }

                        .activity-log-icon {
                            min-width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                width: 24px;
                                height: 24px;
                            }

                            &.activity-create {
                                background-color: #ebf1fb;

                                svg {
                                    path {
                                        color: #2e77f2;
                                    }
                                }
                            }

                            &.activity-status {
                                background-color: #f8e3e3;

                                svg {
                                    path {
                                        color: #fa5757;
                                    }
                                }
                            }

                            &.activity-assign {
                                background-color: #f3e4fc;

                                svg {
                                    path {
                                        color: #62009d;
                                    }
                                }
                            }

                            &.activity-estimation {
                                background-color: #ffecda;

                                svg {
                                    path {
                                        color: #f0841e;
                                    }
                                }
                            }

                            &.activity-edit {
                                background-color: #f1feff;

                                svg {
                                    path {
                                        color: #41dbe7;
                                    }
                                }
                            }

                            &.activity-call {
                                background-color: #e9ffbb;

                                svg {
                                    path {
                                        color: #7cb900;
                                    }
                                }
                            }
                        }

                        .activity-content-wrapper {
                            display: flex;
                            align-items: flex-start;
                            gap: 24px;
                            padding-top: 16px !important;
                            width: 100%;

                            span {
                                color: var(--textgrey);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                white-space: nowrap;
                                line-height: 19px;
                            }

                            .activity-content {
                                max-width: calc(100% - 100px);
                                width: 100%;

                                .activity-content-header {
                                    display: flex;
                                    align-items: baseline;
                                    justify-content: space-between;

                                    h6 {
                                        color: var(--primary-text-color);
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                    }

                                    span {
                                        color: var(--textgrey);
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        white-space: nowrap;
                                        @media (max-width: 768.98px) {
                                            display: none;
                                        }
                                    }
                                }

                                .activity-content-para {
                                    border-radius: 10px;
                                    background: rgba(124, 185, 0, 0.05);
                                    padding: 16px !important;
                                    margin-top: 18px;

                                    p {
                                        color: var(
                                            --primary-text-color
                                        ) !important;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 22px;
                                    }
                                }
                            }
                            @media (max-width: 768.98px) {
                                flex-direction: column;
                                gap: 8px;
                            }
                            .date-time-drawer {
                                display: flex;
                                .mobile-time-drawer {
                                    display: none;
                                    @media (max-width: 768.98px) {
                                        display: flex;
                                        margin-left: 4px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.date-time-drawer {
    display: flex;
    .mobile-time-drawer {
        display: none;
        @media (max-width: 768.98px) {
            display: flex;
            margin-left: 4px;
        }
    }
}
.custom-marign-left {
    margin-left: 3px !important;
}

.kpi-setting-tabs-color {
    background: #232323 !important;
    color: #ffffff !important;
    display: flex !important;
    justify-content: space-between !important;
    text-align: left !important;
    text-transform: capitalize !important;
    max-width: 100% !important;

    span.tabCount {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background: #ffffff;
        align-items: center;
        display: flex;
        justify-content: center;
        color: #000000;
    }
}
.kpi-setting-tabs-noKpi-color {
    background-color: #adb5bd !important;
}

.kpi-setting-tabs-heading-color {
    background: var(--green) !important;
    display: flex !important;
    justify-content: space-between !important;
    text-align: left !important;
    text-transform: capitalize !important;
    max-width: 100% !important;
    color: white !important;
    font-weight: bold;
}

.kpi-setting-tabs-color.Mui-selected {
    background: #ffffff !important;
    color: #000000 !important;

    span.tabCount {
        background: #232323;
        color: #ffffff;
    }
}

.kpi-setting-prev-tabs-border {
    border-radius: 0px 0px 10px 0px !important;
}

.kpi-setting-next-tabs-border {
    border-radius: 0px 10px 0px 0px !important;
}

.tabpanel .MuiAccordion-root {
    border-bottom: 2px solid #232323;
    box-shadow: none;

    .MuiTypography-root {
        color: #232323 !important;
        word-break: break-all;
    }

    .Mui-expanded {
        background: #232323;
        border-radius: 6px;

        .MuiSvgIcon-root {
            fill: #ffffff;
        }

        .MuiTypography-root {
            color: #fff !important;
        }
    }
}

.kpi-mapping-form-group {
    display: flex;
    flex-flow: wrap column;
    max-height: calc(100vh - 255px);

    .designation-label {
        padding-top: 5px;
        padding-bottom: 5px;
        margin-top: 0px;
        margin-bottom: 0px;
        padding-right: 10px;
        .MuiFormControlLabel-label {
            font-size: 16px;
        }
    }

    .MuiRadio-root svg,
    .MuiCheckbox-root svg {
        fill: #232323;
    }
}

.add-kpi-description-box {
    .add-description-btn-dis {
        color: #232323;
        opacity: 0.3;
    }

    .add-description-btn-en {
        color: #232323;
        opacity: 0.6;
        justify-content: flex-start !important;
        width: fit-content;
        &:hover {
            background-color: transparent;
        }
    }

    .css-6od3lo-MuiChip-label {
        font-size: 12px;
        color: #ffffff !important;
        border-radius: 100px;
        gap: 10px;
        height: 21px;
    }

    .css-1u4ma7l-MuiChip-root {
        background-color: #7cb900 !important;
        height: auto;
    }
}

.stickey-column {
    position: sticky;
    background: white;
    z-index: 98 !important;
    left: 0;
    text-align: left !important;
}
.stickey-column2 {
    position: sticky;
    background: white;
    z-index: 98 !important;
}

.stickey-row {
    position: sticky;
    background: white;
    z-index: 98 !important;
    top: 0;
    left: 0;
    &:first-of-type {
        z-index: 99 !important;
    }
}

.add-description-btn-dis {
    color: #232323;
    opacity: 0.3;
}

.fs-10 {
    font-size: 10px !important;
}

.justify-space-between {
    display: flex;
    justify-content: space-between;
}

.text-tran-cap {
    text-transform: capitalize !important;
}

.min-w-200 {
    min-width: 200px !important;
}

.designation-list {
    .designation-tabs {
        min-height: calc(100vh - 96px);
        max-height: calc(100vh - 96px);
        overflow: auto;
        border: none;
        background-color: #f1f3f5;
        .MuiTabScrollButton-root {
            display: none;
        }
    }
}

.kpi-description-tabpanel {
    min-height: calc(100vh - 96px);
    max-height: calc(100vh - 96px);
    overflow: auto;

    .css-19kzrtu {
        padding: 0px;
    }

    .css-1c35hjw-MuiPaper-root-MuiAccordion-root:last-of-type {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .Mui-expanded {
        margin: 0px !important;
    }
}

.kpi-accordion {
    .MuiAccordionSummary-root {
        padding-left: 0px;
        margin-bottom: 5px;
    }
}

.leave-detail .Mui-disabled {
    background-color: hsla(0, 17%, 94%, 0.747);
}

.modal-date-picker.error .rs-picker-toggle.rs-btn,
.modal-date-picker.error + .error {
    border-color: var(--red);
}

.mr-0 {
    margin-right: 0px !important;
}

.remove-num-arr {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.side-drawer-1 {
    .MuiDrawer-paper {
        // min-width: 500px;
        max-width: 900px;
        width: 100%;

        .sidebar-tab-wrapper-1 {
            &.activity-log-wrapper-1 {
                .close-btn {
                    width: 20px;
                    height: 20px;
                    border-radius: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    cursor: pointer;
                    position: absolute;
                    right: 24px;
                    top: 14px;
                    z-index: 99;

                    svg {
                        font-size: 20px;
                        path {
                            color: var(--primary-text-color);
                        }
                    }
                }

                .sidebar-tab {
                    border-bottom: 1px solid var(--border);
                    padding: 0px 24px;

                    .tab-label-btn {
                        padding-left: 0;
                        padding-right: 0;
                        min-width: 120px;
                        // color: var(--textgrey);
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        text-transform: capitalize;

                        &.Mui-selected {
                            color: var(--primary-text-color);
                        }
                    }

                    .MuiTabs-indicator {
                        height: 3px;
                        background-color: var(--primary-text-color);
                        border-radius: 5px;
                        left: 0;
                        width: 120px;
                    }
                }

                .add-activity-log-btn {
                    justify-content: flex-end;
                    display: flex;
                    width: 100%;

                    button {
                        margin-right: 10px;
                    }
                }

                .activity-log-list-wrapper {
                    display: flex;
                    flex-direction: column;
                    max-height: calc(100vh - 150px);
                    padding-right: 10px !important;
                    overflow: auto;

                    .activity-card-wrapper {
                        display: flex;
                        align-items: flex-start;
                        gap: 24px;
                        position: relative;
                        padding-bottom: 36px !important;

                        &:not(:last-child) {
                            &:before {
                                content: '';
                                width: 2px;
                                height: 100%;
                                position: absolute;
                                left: 25px;
                                background: rgba(217, 217, 217, 0.5);
                                z-index: -1;
                            }
                        }

                        .activity-log-icon {
                            min-width: 50px;
                            height: 50px;
                            border-radius: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            svg {
                                width: 24px;
                                height: 24px;
                            }

                            &.activity-create {
                                background-color: #ebf1fb;

                                svg {
                                    path {
                                        color: #2e77f2;
                                    }
                                }
                            }

                            &.activity-status {
                                background-color: #f8e3e3;

                                svg {
                                    path {
                                        color: #fa5757;
                                    }
                                }
                            }

                            &.activity-assign {
                                background-color: #f3e4fc;

                                svg {
                                    path {
                                        color: #62009d;
                                    }
                                }
                            }

                            &.activity-estimation {
                                background-color: #ffecda;

                                svg {
                                    path {
                                        color: #f0841e;
                                    }
                                }
                            }

                            &.activity-edit {
                                background-color: #f1feff;

                                svg {
                                    path {
                                        color: #41dbe7;
                                    }
                                }
                            }

                            &.activity-call {
                                background-color: #e9ffbb;

                                svg {
                                    path {
                                        color: #7cb900;
                                    }
                                }
                            }
                        }

                        .activity-content-wrapper {
                            display: flex;
                            align-items: flex-start;
                            gap: 24px;
                            padding-top: 16px !important;
                            width: 100%;
                            @media (max-width: 768.98px) {
                                flex-direction: column;
                                gap: 8px;
                            }

                            span {
                                color: var(--textgrey);
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 400;
                                white-space: nowrap;
                                line-height: normal;
                            }

                            .activity-content {
                                width: 100%;

                                .activity-content-header {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    gap: 24px;
                                    width: 100%;

                                    h6 {
                                        color: var(--primary-text-color);
                                        font-size: 16px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: normal;
                                        ul {
                                            margin-bottom: 0;
                                            padding-left: 0;
                                        }
                                    }

                                    span {
                                        color: var(--textgrey);
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                        white-space: nowrap;
                                        @media (max-width: 768.98px) {
                                            display: none;
                                        }
                                    }
                                }

                                .activity-content-para {
                                    border-radius: 10px;
                                    background: rgba(124, 185, 0, 0.05);
                                    padding: 16px !important;
                                    margin-top: 18px;

                                    p {
                                        color: var(
                                            --primary-text-color
                                        ) !important;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: 22px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.side-drawer-2 {
    .MuiDrawer-paper {
        width: 100%;
        max-width: 450px;
        .sidebar-tab-wrapper-2 {
            .close-btn {
                width: 20px;
                height: 20px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                cursor: pointer;
                position: absolute;
                right: 24px;
                top: 14px;
                z-index: 99;

                svg {
                    font-size: 20px;
                    path {
                        color: var(--primary-text-color);
                    }
                }
            }

            .sidebar-tab {
                border-bottom: 1px solid var(--border);
                .tab-label-btn {
                    opacity: 1;
                    min-width: 120px;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;

                    &.Mui-selected {
                        color: var(--primary-text-color);
                    }
                }

                .MuiTabs-indicator {
                    height: 3px;
                    background-color: transparent;
                    border-radius: 5px;
                    left: 0;
                    width: 120px;
                }
            }

            .add-activity-log-btn {
                justify-content: flex-end;
                display: flex;
                width: 100%;

                button {
                    margin-right: 10px;
                }
            }

            .filter-content-wrapper {
                width: 1000px;

                @media (max-width: 991.98px) {
                    width: 950px;
                }

                @media (max-width: 768.98px) {
                    width: 750px;
                }
            }

            .activity-log-list-wrapper {
                display: flex;
                flex-direction: column;
                max-height: calc(100vh - 160px);
                padding-right: 10px !important;
                overflow: auto;

                .activity-card-wrapper {
                    display: flex;
                    align-items: flex-start;
                    gap: 24px;
                    position: relative;
                    padding-bottom: 36px !important;

                    &:not(:last-child) {
                        &:before {
                            content: '';
                            width: 2px;
                            height: 100%;
                            position: absolute;
                            left: 25px;
                            background: rgba(217, 217, 217, 0.5);
                            z-index: -1;
                        }
                    }

                    .activity-log-icon {
                        min-width: 50px;
                        height: 50px;
                        border-radius: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        svg {
                            width: 24px;
                            height: 24px;
                        }

                        &.activity-create {
                            background-color: #ebf1fb;

                            svg {
                                path {
                                    color: #2e77f2;
                                }
                            }
                        }

                        &.activity-status {
                            background-color: #f8e3e3;

                            svg {
                                path {
                                    color: #fa5757;
                                }
                            }
                        }

                        &.activity-assign {
                            background-color: #f3e4fc;

                            svg {
                                path {
                                    color: #62009d;
                                }
                            }
                        }

                        &.activity-estimation {
                            background-color: #ffecda;

                            svg {
                                path {
                                    color: #f0841e;
                                }
                            }
                        }

                        &.activity-edit {
                            background-color: #f1feff;

                            svg {
                                path {
                                    color: #41dbe7;
                                }
                            }
                        }

                        &.activity-call {
                            background-color: #e9ffbb;

                            svg {
                                path {
                                    color: #7cb900;
                                }
                            }
                        }
                    }

                    .activity-content-wrapper {
                        display: flex;
                        align-items: flex-start;
                        gap: 24px;
                        padding-top: 16px !important;
                        width: 100%;

                        span {
                            color: var(--textgrey);
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            white-space: nowrap;
                        }

                        .activity-content {
                            max-width: calc(100% - 100px);
                            width: 100%;

                            .activity-content-header {
                                display: flex;
                                // align-items: center;
                                align-items: baseline !important;
                                justify-content: space-between;

                                h6 {
                                    color: var(--primary-text-color);
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                }

                                span {
                                    color: var(--textgrey);
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }

                            .activity-content-para {
                                border-radius: 10px;
                                background: rgba(124, 185, 0, 0.05);
                                padding: 16px !important;
                                margin-top: 18px;

                                p {
                                    color: var(--primary-text-color) !important;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.policy-detail-react-quill {
    .ql-container {
        min-height: 210px;
    }
}
.ql-editor p {
    color: var(--primary-text-color) !important;
}

.bg-white {
    background: #ffffff !important;
}

.border-radius-16 {
    border-radius: 16px;
}

.create-kpi-form {
    .my-select__indicator-separator {
        display: none;
    }

    .my-select__placeholder {
        color: rgba(35, 35, 35, 0.3);
    }

    .my-select__dropdown-indicator {
        color: rgba(35, 35, 35, 0.4) !important;
        .css-tj5bde-Svg {
            fill: rgba(35, 35, 35, 0.4) !important;
        }
    }
}

.kpi-listing {
    tr {
        td.MuiTableCell-body {
            border-bottom: 1px solid rgba(245, 245, 245, 1);
        }
    }
}
.card .css-5wedm5 {
    overflow: visible !important;
}

.fw-600 {
    font-weight: 600 !important;
}

.fw-100 {
    font-weight: 100 !important;
}

.wrap-text-any-where {
    overflow-wrap: anywhere;
}

.policy-detail-value {
    td,
    th {
        border: 1px solid #000000;
        padding: 15px;
    }
}

.ck-editor-wrapper p {
    color: #000000 !important;
}

.policy-detail-value p {
    color: #000000 !important;
    word-break: break-word;
}

.appreciation-header {
    .multi-select-field {
        .select__control {
            width: 200px;
            border: 0px solid;
            border-radius: 8px;
            background: #fff;
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
        }
    }
}

.createKpiTitle {
    margin-bottom: 24px !important;
}

.MuiAccordionSummary-root {
    min-height: 56px !important;
}

.MuiAccordionSummary-root.Mui-expanded {
    min-height: 56px !important;
}
.MuiAccordionSummary-content {
    margin: 12px 0 !important;
}
.MuiAccordionSummary-expandIconWrapper {
    &.Mui-expanded {
        -webkit-transform: rotate(0deg) !important;
        -moz-transform: rotate(0deg) !important;
        -ms-transform: rotate(0deg) !important;
        transform: rotate(0deg) !important;
    }
}
.MuiAccordion-root {
    &.MuiAccordion-rounded {
        box-shadow: none;
        &:before {
            display: none;
        }
    }
    .MuiCollapse-vertical {
        &.MuiCollapse-entered {
            box-shadow: none;
            transition-duration: 301ms !important;
            &:before {
                box-shadow: none;
            }
        }
    }
    .MuiAccordionSummary-root {
        padding-left: 6px;
    }
}

.remove-white-space > .MuiBox-root {
    padding: 24px 0;
}

.holiday-card-inner-wrapper {
    .holiday-card {
        .holiday-date {
            width: 80px;
            min-width: 80px;
            label {
                font-size: 18px !important;
                padding: 12px;
            }
        }
        .holiday-title {
            font-size: 16px !important;
        }
    }
}
.add-wrapper {
    @media (max-width: 1280.98px) {
        flex-direction: column !important;
        align-items: baseline !important;
    }
    @media (max-width: 1024.98px) {
        flex-direction: row !important;
        align-items: center !important;
    }
}

.MuiAccordionSummary-expandIconWrapper {
    transform: rotate(90deg) !important;
}
.Mui-expanded .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(0deg) !important;
}

.kpi-description-tabpanel > .MuiBox-root > p {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.kpi-description-tabpanel {
    padding-top: 24px;
    padding-right: 24px;
    .kpi-accordion {
        border-radius: 0 !important;
        .MuiAccordionSummary-root {
            padding-left: 0;
            padding-right: 8px;
            .MuiAccordionSummary-content {
                .MuiStack-root {
                    align-items: center;
                    p {
                        font-weight: 500;
                    }
                }
            }
            .MuiAccordionSummary-expandIconWrapper {
                transform: rotate(0deg) !important;
                &.Mui-expanded {
                    transform: rotate(180deg) !important;
                }
            }
        }
        .MuiCollapse-vertical {
            .MuiAccordionDetails-root {
                padding: 8px 0;
            }
        }
    }
}

.kpi-mapping-form-group {
    max-height: calc(100vh - 286px);
    min-height: calc(100vh - 286px);
    .MuiFormControlLabel-labelPlacementEnd {
        .MuiFormControlLabel-label {
            font-weight: 500 !important;
        }
    }
}
.designation-tabs {
    border: 0;
}

.add-kpi-description-box {
    .MuiTableCell-head {
        vertical-align: baseline;
        min-width: 280px !important;
        .MuiChip-filledSuccess {
            background-color: #7cb900;
            .MuiChip-label {
                color: #fff;
            }
        }
    }
    .add-description-cell {
        .MuiChip-colorSuccess {
            margin-top: 2px;
            span {
                display: inline-flex !important;
                height: 16px !important;
            }
        }

        .add-description-btn-dis {
            justify-content: flex-start !important;
            font-weight: 500 !important;
        }
        .wrap-text-any-where {
            width: 300px;
            display: inline-block;
            .MuiChip-label {
                display: none;
            }
            .chip-wrapper {
                font-size: 12px;
                color: #fff;
                padding: 4px 8px;
            }
        }
    }
}

.chip-wrapper {
    background-color: #7cb900;
    border-radius: 100px;
    padding: 8px 8px;
}

.appreciation-module-wrapper {
    flex-wrap: wrap-reverse;
    gap: 24px;
}

.policy-module-wrapper {
    flex-wrap: wrap-reverse;
    gap: 24px;
}
.kpi-designation-card {
    align-items: center;
    border: 2px solid var(--textgrey);
    border-radius: 10px;
    overflow: hidden;
    .designation-title {
        background-color: var(--body-bg);
        padding: 8px 16px;
        border-radius: 10px 10px 0 0;
        P {
            font-size: 20px;
            color: var(--primary-text-color) !important;
            font-weight: 600;
        }
    }
}
.kpi-main-wrapper {
    padding-bottom: 70px;
}
.kpi-mapping-table-wrapper {
    margin-bottom: 0;
}
@media (max-width: 1300.98px) {
    .holiday-card-grid-wrapper {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
}
@media (max-width: 1199.98px) {
    .holiday-card-grid-wrapper {
        max-width: 50% !important;
        flex-basis: 50% !important;
    }
}
@media (max-width: 1094.98px) {
    .policy-module-wrapper {
        .content-header-right {
            width: 100%;
            .search-bar {
                width: 100%;
                input {
                    width: 100%;
                }
            }
            .primary-btn {
                flex: 1;
            }
        }
    }
}
@media (max-width: 991.98px) {
    .kpi-mapping-table-wrapper {
        .table-container {
            max-height: calc(100vh - 242px) !important;
            .stickey-row {
                position: initial;
            }
            .stickey-column {
                position: initial;
            }
        }
    }
}
@media (max-width: 1199.98px) {
    .designation-list .designation-tabs {
        min-height: 300px;
        max-height: 300px;
    }
    .kpi-description-tabpanel {
        padding: 0 16px 24px 16px;
        min-height: 200px;
        max-height: fit-content;
    }
}
@media (max-width: 710.98px) {
    .holiday-card-grid-wrapper {
        max-width: 100% !important;
        flex-basis: 100% !important;
    }
    .appreciation-module-wrapper {
        .content-header-right {
            width: 100%;
            .search-bar {
                width: 100%;
                flex: 1;
                input {
                    width: 100%;
                }
            }
        }
    }
    .title-with-btn {
        .btn {
            padding: 4px 12px !important;
        }
    }
}
.content-header-right {
    display: flex;
    gap: 24px;
    align-items: center;
    // justify-content: space-between;
    .search-bar {
      position: relative;
      svg {
        position: absolute;
        top: 10px;
        left: 8px;
        font-size: 18px;
        z-index: 2;
      }
      .cross-line {
        position: absolute;
        top: 10px;
        left: 90%;
        font-size: 18px;
        z-index: 2;
        cursor: pointer;
        fill: #b7b7b7 !important;
      }
      input {
        width: 260px;
        background-color: var(--white);
        padding-left: 32px !important;
        box-shadow: 0px 4px 4px 0px var(--lightgrey);
        border-radius: 10px !important;
        border-color: var(--textgrey) !important;
        @media (max-width: 575px) {
          width: 100%;
        }
      }
      fieldset {
        border-radius: 10px !important;
        border: none !important;
      }
    }
    .primary-btn {
      @media (max-width: 890px) {
        min-width: 160px;
      }
    }
  }
  @media (max-width: 890px) {
    .content-header{
        margin:24px 16px 24px 16px !important;
    }
    .content-header-right{
        width: 100% !important;
        flex-wrap: wrap !important;
        justify-content: space-between;
       
    }
    .content-header-right .add-btn{
        width: 25% !important;
        min-width: 25% !important;
        
    }
  }
@media (max-width: 700px) {
    .content-header-right{
        width: 100% !important;
        flex-wrap: wrap !important;
        // margin:  16px 24px 16px !important;
        justify-content: space-between;
    }
    .content-header-right .search-bar{
        width: 100% !important;
        min-width: 100% !important;
    }
    .content-header-right .search-bar input{
        width: 100% !important;
        min-width: 100% !important;
    }
    .content-header-right .add-btn{
        width: 47% !important;
        min-width: 47% !important;
    }
}

@media (max-width: 464px) {
    .content-header-right{
        width: 100% !important;
        flex-wrap: wrap !important;
    }
    .content-header-right .search-bar{
        width: 100% !important;
        min-width: 100% !important;
    }
    .content-header-right .add-btn{
        width: 46.5% !important;
        min-width: 46.5% !important;
    }
}
@media (max-width: 650.98px) {
    .policy-module-wrapper {
        .content-header-right {
            .policy-template-btn {
                flex: 1 auto;
                width: 100%;
            }
        }
    }
}
@media (max-width: 500.98px) {
    .appreciation-module-wrapper {
        .content-header-right {
            .search-bar {
                flex: 1 auto;
            }
            .primary-btn {
                width: 100%;
            }
        }
    }
}
@media (max-width: 430.98px) {
    .kpi-module-wrapper {
        flex-direction: column-reverse;
        gap: 24px;
        .content-header-right {
            width: 100%;
            .primary-btn {
                flex: 1;
            }
        }
    }
}
@media (max-width: 390.98px) {
    .holiday-card-inner-wrapper {
        .holiday-card {
            gap: 10px;
            .holiday-date {
                width: 50px;
                min-width: 50px;
            }
        }
    }
    .policy-module-wrapper {
        .content-header-right {
            .primary-btn {
                flex: 1 auto;
                width: 100%;
            }
        }
    }
    .kpi-module-wrapper {
        .content-header-right {
            .primary-btn {
                flex: 1 auto;
                width: 100%;
            }
        }
    }
}
@media (max-width: 380px) {
    .content-header-right .add-btn {
        width: 100% !important;
        min-width: 100% !important;
    }
}
.multi-select-field {
    .select__control {
      border-radius: 10px;
      min-height: 40px;
  
      &:hover,
      &:focus,
      &.select__control--is-focused {
        border-color: var(--primary-text-color) !important;
        box-shadow: none;
      }
    }
  
    .select__multi-value {
      border-radius: 6px;
      padding: 6px;
      color: var(--textgrey);
      font-size: 14px !important;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      background-color: rgba(217, 217, 217, 0.5);
    }
  
    .select__menu {
      max-height: none; /* Remove any max-height restriction */
      
      .select__menu-list {
        // color: red !important;
  
        .select__option {
        //   color: red !important;
  
          
          
        }
        .select__option--is-focused{
            background-color:  lightgrey !important;
        }
        .select__option--is-selected{
            background-color:  #8CC714 !important;
        }
      }
    }
  }
  @media (max-width: 360.98px) {
    .holiday-card-inner-wrapper {
        .holiday-card {
            .holiday-title {
                font-size: 14px !important;
            }
            .holiday-date label {
                font-size: 16px !important;
                padding: 8px;
            }
        }
    }
}
p{color: black !important;}
.announcement-ck-editor-wrapper{
    .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
        height: 200px;
        max-height: 200px;
        border-radius: 8px !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    .ck-reset_all :not(.ck-reset_all-excluded *), .ck.ck-reset_all{
       
border-radius: 8px !important;
border-bottom-left-radius: 0px !important;
border-bottom-right-radius: 0px !important; 
    }
    .has-error{
        border-color: red;
    }
}
.footer-wrapper {
    border-top: 1px solid var(--border);
    background: var(--white);
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    position: fixed;
    bottom: 0;
    // max-width: calc(100% - 260px);
    width: 100%;
    z-index: 100;
    left: 0;
    .secondary-btn {
        margin-right: 32px;
        @media (max-width: 991.98px) {
            margin-right: 16px;
        }
    }
}
.error,
.error-message {
    color: var(--red) !important;
    font-size: 14px !important;
    margin-top: 4px;
}
.select-error {
    color: var(--red) !important;
    font-size: 14px !important;
    margin-left: 0px !important;
  }
  .has-error {
    border-color: red;
}
