.dashboard-card-wrapper{
  align-items: center;
  background-color: var(--body-bg);
  border: 2px solid var(--textgrey);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 160px;
  justify-content: center;
  .app-logo {
      margin: 0;
      img{
          height: 70px;
          width: auto;
      }
  }
  .setting-icon {
      margin: 0;
      width: 65px;

      img {
          width: 100%;
      }
  }

  .card-name {
      margin: 0;
      font-size: 20px !important;
      color: var(--primary-text-color);
  }
}