.app-details {
  .app-details-table {
    border-radius: 8px;

    .role-column {
      padding-left: 0 !important;
    }
    .header {
      border-bottom: 1px solid var(--lightgrey);
      padding: 8px 16px;
      margin: 0 !important;
    }
    .row {
      // border-bottom: 1px solid var(--lightgrey);
      padding: 16px;
      p {
        margin: 0;
      }
    }
  }
}
.roles-btn {
  span {
    border-radius: 7px;
    background-color: var(--white);
    color: var(--black);
    font-size: 13px;
    font-weight: 500;
    line-height: 23px !important;
    height: 20px;
    margin-right: 8px;
    width: 20px;
    display: inline-block;
    text-align: center;
  }
}
.app-name {
  display: flex;
  align-items: center;
  gap: 16px;
}

.app-logo {
  .logo-image-wrap {
    display: inline-flex;
    position: relative;
    .logo-image {
      background-color: var(--lightgrey);
      border-radius: 10px;
      height: 120px;
      width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 767px) {
        height: 90px;
        width: 90px;
      }
    }
    button.upld-btn {
      position: absolute;
      right: -10px;
      bottom: -5px;
      height: 32px !important;
      background-color: var(--black);
      border-radius: 50%;
      min-width: 32px !important;
      svg {
        fill: var(--white);
        font-size: 16px;
      }
    }
  }
}
