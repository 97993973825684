.content-header-right {
  display: flex;
  gap: 24px;
  align-items: center;
  .search-bar {
    position: relative;
    svg {
      position: absolute;
      top: 10px;
      left: 8px;
      font-size: 18px;
      z-index: 2;
    }
    .cross-line {
      position: absolute;
      top: 10px;
      left: 90%;
      font-size: 18px;
      z-index: 2;
      cursor: pointer;
      fill: #b7b7b7 !important;
    }
    input {
      width: 260px;
      background-color: var(--white);
      padding-left: 32px !important;
      box-shadow: 0px 4px 4px 0px var(--lightgrey);
      border-radius: 10px !important;
      border-color: var(--textgrey) !important;
      @media (max-width: 575px) {
        width: 100%;
      }
    }
    fieldset {
      border-radius: 10px !important;
      border: none !important;
    }
  }
  .primary-btn {
    @media (max-width: 767px) {
      min-width: 160px;
    }
  }
}
.app-list {
  display: flex;
  align-items: center;
  gap: 12px;

  p {
    margin: 0;
    color: var(--black) !important;
    font-weight: 500;
    span {
      font-weight: 500;
    }
  }
  .plus-users {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20.02px !important;
    letter-spacing: 0.01071em !important;
    margin-left: 4px;
  }
}
.tag-wrap {
  display: flex;
  gap: 8px;
  @media (max-width: 1200px) {
    flex-wrap: wrap;
  }
  tag {
    background-color: var(--light-green);
    text-transform: capitalize;
    color: var(--black);
    border-radius: 4px;
    padding: 4px 12px;
  }
}

.pagination-wrapper {
  // text-align: center;
  margin: 5px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 991.98px) {
      }
      @media (max-width: 767px) {
        flex-direction: column;
      }
  nav {
    margin: 32px 0;
    ul {
      justify-content: center;
      li {
        button {
          font-size: 15px;
        }
        button.Mui-selected {
          background-color: var(--edit-btn);
        }
      }
    }
  }
  .rows-per-page-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
 .MuiPaginationItem-root {
        border-radius: 8px !important;
    }
}

.user-listing {
  .tab-area {
    button {
      span.user-name {
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 600;
      }
    }
    button.Mui-selected {
      span.role-name {
        color: var(--black);
      }
    }
    .MuiTabs-indicator {
      background-color: var(--green);
    }
  }
  .user-listing-tables {
    > .MuiBox-root {
      padding-top: 12px !important;
      p.MuiTypography-root {
        @media (max-width: 991px) {
          overflow-x: auto;
        }
      }
    }
  }
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: none;
  border-radius: 6px;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
  background-color: transparent;
  border-radius: 6;
}

::-webkit-scrollbar-thumb {
  border-radius: 6;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--scrollbar-thumb);
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.add-btn {
  span {
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    font-size: 13px;
    svg {
      fill: var(--white);
    }
  }
}

.mask-box {
  border-radius: 20px;
  position: relative;
  // background-color: var(--primary-btn-hover) !important;
  background-color: transparent !important;
  padding: 3px 3px;
  display: flex;
  padding: 4px;
  border: 1px solid var(--green);
}
.mask-box .mask {
  width: 64px;
  height: 24px;
  font-size: 13px;
  border-radius: 20px;
  background-color: var(--light-green) !important;
  position: absolute;
  transition: all 0.5s ease;
}
.mask-box .MuiButton-root {
  border-radius: 20px;
  width: 64px;
  height: 24px;
  font-size: 13px;
  font-weight: 500;
  color: var(--black);
  padding: 4px 6px;
  transition: all 0.2s 0.1s ease;
  text-transform: capitalize;
}
.mask-box .MuiButton-root:hover {
  opacity: 0.8;
}

.no-msg {
  font-size: 16px;
  font-weight: 500;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.users-module-wrapper{
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.MuiFormControl-root{
  fieldset{
    border-color: rgba(0, 0, 0, 0.23);
    border-radius: 8px;
  }
}
@media(max-width:800.98px){
  .users-module-wrapper{
    .content-header-right{
      flex-wrap: wrap;
      width: 100%;
      .select-field{
        flex: 1;
      }
      .search-bar{
        width: 100%;
        flex: 1;
        input{
          width: 100%;
        }
      }
    }
  }
}
@media(max-width:700.98px){
  .users-module-wrapper{
    .content-header-right{
      button{
        width: 100%;
      }
    }
  }
}
@media(max-width:576.98px){
  .users-module-wrapper{
    .content-header-right{
      .select-field{
        width: 100%;
        flex: 1 auto;
        order: 2;
      }
      .search-bar{
        order: 1;
      }
      button{
        order: 3;
      }
    }
  }
}
.rows-per-page{
  display: flex;
  justify-content: baseline;
  align-items: baseline;
  .MuiSelect-select{
    // border: 2px solid red;
padding-top: 21px !important;
  }
}
.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active{
  background-color: rgb(124, 185, 0) !important;
}

.rs-calendar-time-view .rs-calendar-header-title-time{
  color: rgb(124, 185, 0) !important;
}