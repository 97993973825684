.release-card-wrapper{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    .release-title{
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        .application-name-text{
            font-size: 20px;
            color: var(--black) !important;
            font-weight: 600;
            line-height: normal;
            white-space: nowrap;
        }
        .version-text {
            font-size: 16px;
            color: var(--black) !important;
            font-weight: 500;
            line-height: normal;
            white-space: nowrap;
        }
    }
    .date-text {
        margin-top: 8px !important;
        font-size: 16px;
        color: var(--grey);
        font-weight: 500;
        line-height: normal;
        font-style: italic;
    }
    .down-arrow {
        font-size: 32px;
        fill: #777;
        cursor: pointer;
    }
    .rotate-transition {
        transition: transform 0.3s ease; 
    }    
    .action-btn-wrap{
        display: flex;
        gap: 16px;
    }
    .release-action-container{
        display: flex;
        flex-direction: row;
        align-items: center;  
        gap: 16px;     
    }
}

.release-notes-wrapper {
    .release-note{
        border-bottom: 1px solid #ccc;
        padding: 20px;
        
        &:last-child {
            border-bottom: 1px solid #ccc;
            border-radius: 10px;
        }      

        .description-content{
            background-color: #f9f9f9;
            margin-top: 20px;

            .ql-container{    
                p{
                    color: var(--black) !important;
                }
            }
        }
    }
}
.release-form-wrapper{
    margin-bottom: 100px;
}
.load-more-section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    .loader {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      animation: rotate 1s linear infinite
    }
    .loader::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #000;
      animation: prixClipFix 2s linear infinite ;
    }

    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }

    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
}
.MuiChip-label{
    color: white !important;
    
}
.MuiChip-root{
    border-radius: 50px !important;
    padding: 0px 4px !important;
    
}
.create-release-note-wrapper{
    .description-text-box{
        .ql-toolbar{
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }
        .ql-container{
            min-height: 200px;
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;

            p{
                color: var(--black) !important;
            }
        }
    }
}

.release-module-wrapper{
    gap: 24px;
    flex-wrap: wrap-reverse;
}
@media(max-width:644.98px){
    .release-module-wrapper{
        .content-header-right{
            width: 100%;
            .relase-select-field{
                flex: 1;
                width: 100% !important;
                .select-field{
                    width: 100%;
                }
            }
        }
    }
}
@media(max-width:480.98px){
    .release-module-wrapper{
        
        .content-header-right{
            flex-direction: column;
            width: 100%;
            button{
                width: 100%;
            }
        }
    }
}
@media(max-width:390.98px){
    .release-card-wrapper{
        gap: 12px;
        .release-title{
            .application-name-text{
                font-size: 16px;
            }
        }
        .release-action-container{
            flex-direction: column-reverse;
            gap:8px;    
            justify-content: end;
            align-items: end;
        }
    }
}