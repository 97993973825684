.role-info {
  .tab-wrapper {
    button {
      span.role-name {
        margin-right: 8px;
        color: var(--primary-text-color);
        font-weight: 500;
        font-size: 16px;
        text-transform: capitalize;
      }
      span.module-count {
        border-radius: 100px;
        background: var(--green);
        font-size: 14px;
        padding: 4px 7px;
        color: var(--white);
        align-items: center;
        justify-content: center;
        line-height: 16px;
      }
    }
    button.Mui-selected {
      span.role-name {
        color: var(--black);
      }
      span.module-count {
        background: var(--green);
      }
    }
    .MuiTabs-indicator {
      background-color: var(--green);
      height: 3px;
      border-radius: 12px;
    }
    .MuiTabs-root {
      .MuiTabs-scroller {
        margin-bottom: 6px;
        &::-webkit-scrollbar {
          width: 2px;
          height: 2px;
          border-radius: 6px;
          position: relative;
          bottom: 8px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #717171;
          outline: 1px solid #717171;
          border-radius: 26px;
        }
      }
    }
  }
}

.role-list-wrapper-scroll {
  @media (max-width: 767px) {
    overflow-x: auto;
  }
  .role-list-wrapper {
    border: 1px solid var(--lightgrey);
    border-radius: 8px;
    thead {
      tr {
        th.permission-th {
          @media (max-width: 767px) {
            min-width: 480px;
          }
        }
      }
    }
    tr {
      td {
        p {
          color: var(--black) !important;
        }
      }
    }
    thead {
      tr.table-head {
        th {
          box-sizing: border-box;
          &:first-child {
            width: 100px;
          }
          &:nth-child(2) {
            width: 400px;
          }
          &:nth-child(3) {
            width: 700px;
          }
        }
      }
    }
  }
}

.create-role-wrap {
  .role-detail {
    margin-bottom: 12px;
  }
}

.footer-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: var(--white);
  border-top: 1px solid #ddd;
  border-top: 1px solid var(--border);
  bottom: 0;
  left: 0;
  padding: 16px 32px;
  position: fixed;
  width: 100%;
  z-index: 0;
  .btn-group {
    gap: 16px;
    display: flex;
    button{
      min-width: 100px;
    }
  }
  @media(max-width: 991.98px){
    padding: 16px;
  }
}

.permission-button {
  button {
    padding: 4px 16px;
    text-transform: capitalize;
    border-radius: 10px;
  }
  button.Mui-selected {
    background-color: var(--light-green) !important;
  }
}
.role-list {
  @media (max-width: 767px) {
    overflow-x: auto;
  }
}
.role-title{
  padding-left: 24px;
  margin-bottom: 0 !important;
}
.application-role-module-wrapper{
  flex-wrap: wrap-reverse;
  gap: 24px;
}
.role-form-wrapper{
  margin-bottom: 100px;
}
@media (max-width: 768.98px) {
  .role-title{
    padding-top: 24px;
  }
}
@media (max-width: 480.98px) {
  .application-role-module-wrapper{
    flex-wrap: wrap-reverse;
    .content-header-right{
      width: 100%;
      button{
        width: 100%;
      }
    }
  }
}