.main-wrap {
  background-color: var(--black);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 40px;
  .logo-wrap {
    text-align: center;

    @media (max-width: 991px) {
      margin-top: 32px;
    }
    h2 {
      margin: 12px 0 0 0;
      color: var(--white);
    }
    img {
      width: 200px;
    }
  }
  .form-wrap {
    background-color: var(--white) !important;
    border-radius: 12px;
    padding: 32px;
    position: relative;
    z-index: 2;
    @media (max-width: 767px) {
      width: 85%;
      padding: 16px;
    }
    .title {
      text-align: center;
      margin-bottom: 24px;
      h3 {
        margin: 0;
      }
      p {
        margin: 12px 0 0 0 !important;
        @media (max-width: 767px) {
          font-size: 15px;
        }
      }
    }
    .remember-me {
      display: inline-flex !important;
    }
    .error {
      color: var(--red) !important;
      font-size: 14px !important;
    }
    .error-msg {
      color: var(--red) !important;
      font-size: 14px !important;
      margin-left: 16px !important;
    }
    button.primary-btn {
      width: 100%;
      margin-top: 24px;
    }
    .forgot-pw {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 16px;
      .link-btn {
        margin-top: 12px !important;
        padding: 0 !important;

        a {
          color: var(--black) !important;
        }
      }
    }
    .link-btn-wrap {
      display: flex;
      justify-content: center;
      margin-top: 8px;
    }
    .email-logo-wrap {
      text-align: center !important;
      margin-top: 24px;
      margin-bottom: 16px;
    }
  }
  .pattern-big,
  .pattern-small {
    z-index: 1;
  }
  .pattern-big {
    top: -50px;
    left: -170px;
  }
}
