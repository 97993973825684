// Navbar Styles //
.navbar {
  position: sticky !important;
  z-index: 10 !important;
  background-color: var(--white) !important;
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px 0 #00000024, 0 1px 10px 0 #0000001f;
  .small-logo {
    display: none;
  }
  &.sidebar-closed {
    div {
      .navbar-left {
        gap: 0;
        min-width: 0px;
        height: 64px;
        padding: 0;
        background-color: transparent;
        .small-logo {
          display: none;
        }
        .large-logo {
          display: none;
        }
        .toggle-btn {
            background-color: var(--green);
            border-radius: 100%;
            height: 30px;
            left: 45px;
            min-width: 30px;
            padding: 0;
            position: fixed;
            top: 16px;
            width: 30px;
          .open-menu {
            display: none;
          }
          .close-menu {
            display: block;
          }
        }
      }
      .navbar-right {
        padding-left: 45px;
      }
    }
  }
  div {
    padding: 0;
    .navbar-left {
      background-color: #232323;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      min-width: 300px;
      height: 64px;
      padding: 12px 8px 12px 18px;
      box-sizing: border-box;
      cursor: pointer;
      transition: all 0.5s;
      -webkit-transition: all 0.25s;
      
      img {
        width: auto;
        height: 37px;
      }
      svg {
        fill: var(--white);
      }
      .toggle-btn {
        .close-menu {
          display: none;
        }
      }
    }

    .navbar-right {
      margin: 0 32px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .user-info {
        display: flex;
        align-items: center;
        gap: 16px;
        // margin-right: -10px;
        font-size: 16px;
        font-weight: 500;
        button {
          span {
            text-transform: capitalize;
            font-size: 16px !important;
            font-weight: 500 !important;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
}
// Navbar Styles END //

.account-dropdown-menu {
  .MuiMenu-paper {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    border-radius: 10px;
    // left: 61px !important;
    .user-details {
      .user-img {
        display: flex;
        justify-content: center;
        align-items: center;
        
        @media (max-width: 1560.98px) {
          width: 100%;
          height: 100%;
        }
        img {
          object-fit: contain;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: rgb(204, 204, 204);
        }
        
      }
      .user-name {
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 24px;
        text-align: center;
      }
      .user-position {
        text-align: center;
        display: block;
        font-weight: 400 !important;
        font-size: 13px;
        line-height: 18px;
      }
    }
    .divider-border {
      background: var(--light-green);
      margin: 15px 0px;
    }
    .organization-details {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;
      gap: 10px;
      .org-img {
        width: 30px;
        height: 30px;
        background-color: var(--black);
        padding: 2px;
        border-radius: 100px;
        border: 2px solid var(--green);
        margin-bottom: 5px;
        img {
          width: 100%;
          border-radius: 100%;
        }
      }
      .org-text {
        h5 {
          font-weight: 500 !important;
          font-size: 15px !important;
          white-space: nowrap;
        }
        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          position: relative;
          top: -6px;
        }
      }
    }
    .links {
      gap: 15px;
      display: flex;
      flex-direction: column;
      .menu-link {
        padding: 0;
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        min-height: auto;
        &:hover,
        &:focus {
          background-color: transparent;
        }
        .link-icon {
          margin-left: 6px;
          min-width: 22px;
          opacity: 0.6 !important;
          font-size: 18px !important;
        }
        .link-text{
          margin-left: 10px;
        }
      }
    }
  }
}
@media(max-width:991.98px){
  .user-info{
    svg{
      font-size: 32px !important;
      width: 32px;
    }
    .MuiAvatar-circular{
      width: 30px;
      height: 30px;
    }
  }
  .navbar{
    .navbar-left {
      position: fixed;
    }
    .navbar-right{
      margin: 0 16px !important;
      padding-left: 0px;
    }
    &.sidebar-closed {
      div {
        .navbar-left {
          .toggle-btn{
            left: 26px;
          }
        }
        .navbar-right{
          padding-left: 40px;
        }
      }
    }
  }
}
@media(max-width:768.98px){
  .navbar{
      .navbar-right{
        h1{
          font-size: 20px;
        }
      }
    }
}