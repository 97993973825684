// Sidebar Styles //
.sidebar-wrapper {
  z-index: 15 !important;
  .MuiDrawer-paperAnchorDockedLeft{
    border: none;
  }
  .logo-box {
    display: flex;
    justify-content: space-between;
    align-items: center;

    gap: 0;
    padding: 10px;
    padding-left: 18px;
    padding-right: 0;
    .small-logo {
      display: block;
      width: 36.5px;
      position: relative;
      left: 10px;
    }
    .large-logo {
      width: auto;
      height: 37px;
    }
    .toggle-btn {
      position: relative;
      left: 8px;
     
      .open-menu {
        font-size: 25px;
      }
      .close-menu {
        display: block;
      }
    }
  }

  .MuiDrawer-paper {
    transition: all 0.5s !important;
    -webkit-transition: all 0.25s !important;
    overflow: hidden;
  }
  .list-wrap {
    height: 100vh;
    background-color: #232323;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.5s;
    -webkit-transition: all 0.25s;
    .logo-box {
      box-shadow: 0 2px 4px -1px #0003, 0 4px 5px 0 #00000024, 0 1px 10px 0 #0000001f;
      height: 64px;
      padding: 11px 15px;     
    }
   
    ul.sidebar {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding-top: 16px;
      
      li {
        padding: 0 4px;
        .icon-wrap {
          min-width: 35px !important;
          svg {
            fill: var(--textgrey);
            font-size: 25px;
          }
        }
        span {
          color: var(--textgrey);
        }
        &:hover {
          svg {
            fill: var(--green);
          }
          span {
            color: var(--white);
          }
        }
      }
    }
    li.activemenu {
      .icon-wrap {
        svg {
          fill: var(--green) !important;
        }
      }
      span {
        color: var(--white) !important;
      }
    }
  }
  .Menu-list-wrapper-sidebar {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: calc(100% - 65px);
    justify-content: space-between;
    .first-menu-list {
      overflow: auto;
      height: calc(100% - 71px);
    }
    .last-menu-list {
    }
  }
  
}
// Sidebar styles END //
.no-scroll {
  overflow: hidden;
}
