// Color Variables //
:root {
 --black: #323232;
    --grey:#777777;
  --white: #ffffff;
  --lightgrey: #f0f0f0;
  --textgrey: #a5a5a5;
  --red: #ee5e5e;
  --green: #7cb900;
  --light-green: #ebf5d9;
  --body-bg: #f4f4f4;

  --primary-btn-hover: #474747;
  --secondary-btn-hover: #e0e0e0;

  --view-btn: #edf8f5;
  --view-btn-icon: #4cbc9a;
  --view-btn-hover: #def5ef;

  --edit-btn: #e9e9e9;
  --edit-btn-hover: #e2dede;

  --password-btn: #f1f5ff;
  --password-btn-hover: #d9e2f8;
  --scrollbar-thumb: #d9d9d9;
  --delete-btn: #ffeff0;
  --delete-btn-icon: #ff606b;
  --delete-btn-hover: #fbdfe1;
  --border: #dddddd;
}
.text-green {
  color: var(--green) !important;
}

.text-black {
  color: var(--black) !important;
}

.text-white {
  color: var(--white);
}

.text-grey {
  color: var(--grey) !important;
}
// Color Variables END //
@import "rsuite/dist/rsuite.css";

// Font Faces //
@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-SemiBold.eot");
  src: url("../assets/fonts/Mona-Sans-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-SemiBold.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-SemiBold.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-SemiBold.svg#Mona-Sans-SemiBold")
      format("svg");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Regular.eot");
  src: url("../assets/fonts/Mona-Sans-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Regular.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Regular.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Regular.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Regular.svg#Mona-Sans-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Medium.eot");
  src: url("../assets/fonts/Mona-Sans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Medium.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Medium.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Medium.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Medium.svg#Mona-Sans-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Bold.eot");
  src: url("../assets/fonts/Mona-Sans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Bold.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Bold.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Bold.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Bold.svg#Mona-Sans-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Mona-Sans";
  src: url("../assets/fonts/Mona-Sans-Light.eot");
  src: url("../assets/fonts/Mona-Sans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Mona-Sans-Light.woff2") format("woff2"),
    url("../assets/fonts/Mona-Sans-Light.woff") format("woff"),
    url("../assets/fonts/Mona-Sans-Light.ttf") format("truetype"),
    url("../assets/fonts/Mona-Sans-Light.svg#Mona-Sans-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  background-color: var(--body-bg) !important;
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #717171;
    outline: 1px solid #717171;
    border-radius: 26px;
  }
}
* {
  font-family: "Mona-Sans" !important;
  color: var(--black);
}
h1 {
  font-size: 26px !important;
  font-weight: bold !important;
  @media (max-width: 992px) {
    font-size: 22px !important;
  }
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
}
h2 {
  font-size: 24px !important;
  font-weight: 600 !important;
  @media (max-width: 992px) {
    font-size: 20px !important;
  }
  @media (max-width: 767px) {
    font-size: 18px !important;
  }
  @media (max-width: 480px) {
    font-size: 17px !important;
  }
}
h3 {
  font-size: 18px !important;
  font-weight: 600 !important;
  @media (max-width: 480px) {
    font-size: 16px !important;
  }
}
h4 {
  font-size: 18px !important;
  font-weight: 500 !important;
}
h5 {
  font-size: 16px !important;
  font-weight: 600 !important;
}
p {
  color: var(--textgrey) !important;
}
// Font Faces END //

// Content Layout //
.content-header {
  margin: 24px 32px 0px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    margin: 24px 16px 0px 16px;
  }
  h2 {
    margin: 0;
    span {
      font-size: 24px !important;
      font-weight: 600 !important;
      @media (max-width: 767px) {
        font-size: 18px !important;
      }
    }
  }
}
.content-layout {
  border-radius: 12px;
  background-color: var(--white);
  margin: 24px 32px 32px 32px;
  @media (max-width: 992px) {
    margin: 24px 16px 24px 16px;
  }
  h3 {
    margin-top: 0px;
    margin-bottom: 16px;
    span {
      font-weight: 600;
    }
  }
  .error,
  .error-message {
    color: var(--red) !important;
    font-size: 14px !important;
    margin-top: 4px;
  }
  .select-error {
    color: var(--red) !important;
    margin-left: 0px;
  }
}
.content-layout.applist-wrapper {
  @media (max-width: 992px) {
    overflow-x: auto;
  }
}
.full-width {
  margin-left: 0 !important;
}
.admin {
  margin-left: 300px;
  transition: all 0.5s;
  -webkit-transition: all 0.25s;
}

.non-admin {
  margin-left: 0px !important;
  .modal-wrapper-change {
    .error {
      color: var(--red) !important;
      font-size: 14px !important;
    }
  }
}

.p-24 {
  padding: 24px;
  @media (max-width: 992px) {
    padding: 16px;
  }
}

.pattern-big,
.pattern-small {
  position: absolute;
  z-index: -1;
}
.pattern-big {
  top: 0;
  left: 0;
  img {
    @media (max-width: 992px) {
      width: 80%;
    }
  }
}
.pattern-small {
  right: 0;
  bottom: 0;
  @media (max-width: 992px) {
    position: relative;
    display: none;
  }
  img {
    @media (max-width: 992px) {
      width: 80%;
      right: 0;
      position: absolute;
    }
  }
}

// Content Layout END //

// Common Styles //
.btn {
  border-radius: 10px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  padding: 8px 16px !important;
  min-height: 42px;
}
.primary-btn {
  background-color: var(--black) !important;
  color: var(--white) !important;
  &:hover {
    background-color: var(--primary-btn-hover) !important;
  }
}
.primary-btn {
  p {
    margin: 0;
    line-height: 21px;
    vertical-align: middle;
    color: var(--white) !important;
  }
}
.secondary-btn {
  background-color: var(--lightgrey) !important;
  color: var(--black) !important;
  &:hover {
    background-color: var(--secondary-btn-hover) !important;
  }
}
.link-btn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: var(--black) !important;
  text-decoration: underline !important;
  margin: 0 !important;
  &:hover {
    background-color: transparent !important;
  }
}
.outline-btn {
  text-transform: capitalize !important;
  font-size: 14px !important;
  color: var(--black) !important;
  border-color: var(--black) !important;
  border-width: 1.5px !important;
  &:hover {
    background-color: var(--lightgrey) !important;
  }
}

.action-btn {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px !important;
  height: 40px;
  padding: 0 !important;
  border: none !important;
  span {
    margin: 0;
  }
}

label,
span.label {
  color: var(--black) !important;
  font-size: 13px !important;
  margin-bottom: 4px !important;
  display: flex !important;
  font-weight: 700 !important;
  line-height: 15px;
}
label.empty-label {
  height: 15px;
}
label span.asterisk {
  color: var(--red);
  font-size: 18px;
  line-height: 15px;
  font-weight: 600;
}

.value {
  color: var(--black) !important;
  margin: 0 !important;
  line-height: 18px !important;
  @media (max-width: 767px) {
    font-size: 14px !important;
  }
}

.MuiRadio-root,
.MuiCheckbox-root {
  svg {
    fill: var(--textgrey);
  }
}
.MuiRadio-root.Mui-checked,
.MuiCheckbox-root.Mui-checked {
  svg {
    fill: var(--green);
  }
}
.MuiInputBase-input.MuiOutlinedInput-input,
.MuiInputBase-input.MuiOutlinedInput-input {
  font-size: 14px;
  height: 40px;
  box-sizing: border-box;
}
.title-with-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  button.back-btn {
    background-color: transparent !important;
    padding: 0 !important;
    min-width: auto;
    border: none;
    svg {
      font-size: 30px;
    }
  }
}
.listing-table {
  .MuiTableCell-root {
    @media (max-width: 767px) {
      padding: 8px;
    }
    span {
      font-size: 14px;
    }
  }
}
.page-not-found {
  height: 100vh;
  .page-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 48px;
    @media (max-width: 767px) {
      margin: 32px 24px;
    }
    img {
      width: 35%;
      @media (max-width: 580px) {
        width: 80%;
      }
    }
    h5 {
      font-size: 22px !important;
      margin-bottom: 8px;
      @media (max-width: 767px) {
        font-size: 18px !important;
      }
    }
    p {
      font-size: 20px;
      margin-bottom: 24px;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
}
.access-denied {
  height: 100vh;
  .page-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100vh;
      img {
          max-width: 100%;
      }
      .page-404 {
          max-width: 35%;
          margin-bottom: 20px;
      }
      h3 {
          font-size: 36px !important;
          margin-bottom: 8px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 2px;
      }
      p {
          font-size: 20px;
          margin-bottom: 24px;
          color: var(--primary-btn-hover) !important;
      }
  }
}
// Common Styles END //

// Modal Styles //
.MuiModal-root {
  z-index: 9999;
  @media only screen and (min-device-width: 480px) and (max-device-width: 740px) and (orientation: landscape) {
    overflow-y: auto;
  }
}
.MuiModal-root {
  .MuiModal-backdrop {
    backdrop-filter: blur(5px);
  }
}
.MuiPopover-root {
  .MuiModal-backdrop {
    backdrop-filter: inherit;
  }
}

.modal-wrapper {
  padding: 24px;
  max-width: 580px;
  width: 100%;
  width: calc(100% - 32px);
  border-radius: 12px;
  background-color: var(--white);
  border: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .title {
    h3 {
      margin: 0;
      line-height: 27px;
    }

    p {
      color: var(--textgrey);
      font-size: 16px;
      margin: 8px 0 0 0;
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
  .error {
    color: var(--red) !important;
    font-size: 14px !important;
  }
  .footer {
    margin-top: 36px;
    .btn-group {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 12px;
      .btn {
        min-width: 100px;
      }
    }
  }
  .status-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .fail-icon {
    align-self: center;
    color: var(--red);
    font-size: 52px;
  }
  .success-icon {
    align-self: center;
    color: var(--green);
    font-size: 52px;
  }
  .warning-icon {
    align-self: center;
    color: rgb(238, 255, 0);
    font-size: 52px;
  }
  .feedback-text {
    border-radius: 10px;
    padding: 20px;

    p {
      margin-bottom: 0;
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: var(--color-black);
    }
  }
  .fail-text {
    background: rgba(195, 90, 103, 0.2) !important;
  }
  .success-text {
    background: rgba(90, 195, 92, 0.2) !important;
  }
  .warning-text {
    background: rgba(183, 195, 90, 0.2) !important;
  }
  .confirmation-text {
    background: rgba(140, 199, 20, 0.2) !important;
    border: 1px solid var(--color-green);
    border-radius: 10px;
    padding: 20px;
  }
}
.modal-ui{
  padding: 0;
  .modal-body{
      padding: 24px;
  }
}
.modal-wrapper-change{
  margin-bottom: 100px;
}
// Modal Styles END //

// Drop Zone Styles //
.drop-zone {
  margin-top: 40px;
  .drop-zone-inner {
    background-color: var(--lightgrey);
    box-shadow: none;
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .msg-wrap {
      border: 2px dotted var(--textgrey);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: var(--white);
      width: 310px;
      height: 310px;
      margin: 16px;
      border-radius: 50%;
      cursor: pointer;
      @media (max-width: 767px) {
        margin: 8px;
      }
      @media (max-width: 480px) {
        height: 285px;
      }
      h3 {
        @media (max-width: 480px) {
          margin-bottom: 8px;
        }
      }
      h6 {
        color: var(--black);
        font-size: 15px;
        margin: 8px 0 16px 0 !important;
      }
      p.sub-msg {
        font-size: 13px;
        margin: 0;
        color: var(--black) !important;
        @media (max-width: 480px) {
          margin: 2px 0;
          font-size: 12px;
        }
        span {
          font-weight: 600;
          font-size: 13px;
          @media (max-width: 480px) {
            font-size: 12px;
          }
        }
      }
      svg {
        font-size: 28px;
      }
    }
  }
}
// Drop Zone Styles END //

// Action Button Styles //
.action-btn.view-btn {
  background-color: var(--view-btn);
  span {
    svg {
      fill: var(--view-btn-icon);
    }
  }
  &:hover {
    background-color: var(--view-btn-hover);
  }
}
.action-btn.edit-btn {
  background-color: var(--edit-btn);
  span {
    svg {
      fill: var(--black);
    }
  }
  &:hover {
    background-color: var(--edit-btn-hover);
  }
}

.action-btn.password-btn {
  background-color: var(--password-btn);
  span {
    svg {
      fill: var(--black);
      font-size: 23px;
    }
  }
  &:hover {
    background-color: var(--password-btn-hover);
  }
}

.action-btn.dlt-btn {
  background-color: var(--delete-btn);

  span {
    svg {
      fill: var(--delete-btn-icon);
    }
  }
  &:hover {
    background-color: var(--delete-btn-hover);
  }
}
// Action Button Styles END //

// Table Styles//
Table.listing-table {
  thead {
    tr {
      th {
        font-weight: 700;
      }
      th.th-role-name {
        width: 80%;
      }
      th.th-action {
        width: 20%;
      }
    }
  }
  .action-btn-wrap {
    display: flex;
    gap: 16px;
  }
  tbody {
    position: relative;
    .table-no-data {
      width: 100%;
      display: block;
      text-align: center;
      padding: 40px 0;
      position: absolute;
      background-color: var(--white);
      height: 450px;
      margin-top: -6px;
    }
  }
}

.table-no-data-tbody {
  position: relative;
  background-color: var(--white);
  height: 500px;
}
.table-no-data {
  width: 100%;
  display: block;
  text-align: center;
  margin: 40px 0;
  position: absolute;
  img {
    width: 160px;
  }
  h5 {
    margin: 0 0 16px 0;
  }
  P {
    margin: 4px;
  }
  button {
    margin-top: 24px;
  }
}

// Table Styles END//

// Date Picker Design

.add-release-note-datepicker {
  border: 1px solid #ccc !important;
  min-height: 40px;
  display: flex;
  border-radius: 4px;

  svg {
    margin-top: 1px;
  }
  &:hover {
    border-color: var(--black);
  }
}

.rs-stack-item {
  display: flex !important;
}

.rs-picker-daterange-header {
  display: none !important;
}

.rs-picker-daterange-calendar-group .rs-calendar:last-child {
  display: none;
}

.rs-picker-daterange-calendar-group {
  min-width: auto;
}

.rs-picker-menu {
  z-index: 9999 !important;
}
.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-menu .rs-picker-toolbar-ranges {
  flex-direction: column;
}

.rs-picker-toggle {
  border: 1px solid var(--textgrey);
  border-radius: 10px !important;
}
.rs-picker-toggle:hover {
  border-color: var(--green) !important;
}
.rs-picker-toggle-active,
.rs-picker.rs-picker-focused {
  box-shadow: none;
  border-color: var(--green) !important;

  &:hover {
    border-color: var(--green) !important;
  }
}

.rs-btn-icon {
  background: var(--green);
  border: 1px solid var(--green);
  border-radius: 5px;
  padding: 0;
}

.rs-btn-icon svg {
  color: var(--white);
  font-size: 20px !important;

  &:hover {
    fill: var(--green) !important;
  }
}

.rs-calendar-header-title {
  margin: 0 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
}

.rs-calendar-table-header-cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-content span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
.rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content {
  background: var(--green);
  color: var(--white) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--green);
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background: var(--green) !important;
  span {
    color: var(--white) !important;
  }
}

.rs-calendar-table-cell-content:hover {
  background-color: var(--green);
  span {
    color: var(--white) !important;
  }
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell-selected:hover
  .rs-calendar-table-cell-content {
  background-color: var(--green);
  color: var(--white) !important;
}

.rs-calendar-table-cell:hover {
  background-color: transparent !important;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--green);
  color: var(--white);
}

.rs-calendar-table-cell-disabled {
  div {
    span {
      color: var(--grey) !important;
    }
  }
}
.rs-calendar-table-cell-un-same-month {
  div {
    span {
      color: var(--grey) !important;
    }
  }
}

.rs-calendar-table-cell-in-range::before {
  background: rgba(140, 199, 20, 0.3);
  border-top: 1px solid var(--green);
  border-bottom: 1px solid var(--green);
}

.rs-stack-item {
  width: 100%;
  text-align: center;
}

.rs-stack-item .rs-btn-link {
  width: 171px;
  text-align: center;
  border: 1px solid var(--green);
  border-radius: 5px;
  color: var(--color-black) !important;
  text-decoration: none !important;
}

.rs-stack-item .rs-btn-link:hover {
  background: rgba(140, 199, 20, 0.3);
}

.rs-picker-toolbar-right .rs-btn.rs-btn-primary.rs-btn-sm {
  width: 100%;
  background-color: var(--green) !important;
  color: var(--white) !important;
  top: 5px;
}

.rs-picker-toolbar-right {
  width: 100%;
}

.rs-calendar-month-view .rs-calendar-header-title-date {
  color: var(--green);
}

.rs-calendar-month-dropdown-year-active {
  color: var(--green);
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--green) !important;
  color: var(--white) !important;
}

.rs-picker-menu .rs-picker-toolbar {
  flex-direction: column;
}

.rs-picker-toolbar .rs-stack-item {
  max-width: 171px;
}

.rs-picker-toggle-read-only {
  opacity: 1;
}

.rs-calendar-header-backward {
  svg {
    fill: var(--white);
  }
}
.rs-calendar-header-forward {
  svg {
    fill: var(--white);
  }
}

.MuiAvatar-img {
  object-fit: contain !important;
}
.MuiTextField-root{
  .MuiOutlinedInput-root{
    border-radius: 8px;
  }
}
.select-field{
  fieldset{
    border-color: transparent !important;
  }
}
.divider {
  border-color: rgb(224, 224, 224);
}
.content-wrapper{
  margin: 24px 32px 32px 32px;
}
.card-wrapper{
  background-color: var(--white);
  border-radius: 12px;
}
.user-field-module-wrapper{
  margin-bottom: 100px;
}
@media(max-width:991.98px){
  .back-btn{
    display: none !important;
  }
  .content-wrapper{
    margin: 24px 16px 24px 16px;
  }
  .admin{
    margin-left: 0 !important;
  }
}